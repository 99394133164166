<template>
    <div class="media-block">
        <a href="javascript:;"  class="media">
            <div class="content-media">
                <span class="slogon-text white-text">
                    SELLER UNIVERSITY
                </span>
                <h2 class="mt-3">
                    <strong  class="white-text">
                        {{ question }}
                    </strong>
                </h2>
                <div class="play-block">
                    <span class="white-text">Voir la video</span>
                </div>
            </div>
        </a>
    </div>
</template>

<style lang="scss" scoped>
    @import "~@/assets/styles/variables";
    .media-block {
        background: gray;
        border-radius: 14px;
        height: 566px;
        box-shadow: 0px 0px 30px #00000019;
        transition: all .2s cubic-bezier(.64,.15,.53,.53);
    }

    .media-block:hover {
        box-shadow: 0 0 24px rgba(0, 0, 0, .2);
        transition: all .2s cubic-bezier(.64,.15,.53,.53);
        transform: scale(1.03);
    }

    .media {
        height: 566px;
        background-color: #00000030;
    }

    .content-media {
        padding: 80px 30px;
        position: relative;
        height: 536px;
    }

    .play-block {
        position: absolute;
        bottom: 0;
        span {
            font-weight: bold;
            font-size: 24px;
            position: relative;
            padding-left: 50px;
        }

        span::before {
            content: "";
            position: absolute;
            left: 0;
            top: -5px;
            height: 50px;
            width: 50px;
            background-image: url("~@/assets/imgs/play_button.svg");
            background-repeat: no-repeat;
            background-size: 40px;
        }
    }
</style>

<script>
export default {
  props: {
    question: String,
  },
  data () {
    return {
    }
  },
    methods: {

    },
}
</script>