<template>
  <div id="app">
    <tq-header/>
    <router-view class="content"/>
    <transition name="fade">
      <div class="chat-message d-none d-lg-block" v-if="!chatOpened">
        Salam! Pouvons-nous vous aider ?
      </div>
    </transition>
    <transition name="fade">
      <div class="chat-messenger" v-show="chatOpened">
        <a href="http://m.me/LivraisonALTHIQA" target="_blank">
          <img src="~@/assets/imgs/messenger.svg" alt="whatsapp" width="28px">
        </a>
      </div>
    </transition>
    <transition name="fade">
      <div class="chat-whatsapp" v-show="chatOpened">
<!--        <a href="https://api.whatsapp.com/send?phone=0666135442" target="_blank">-->
           <a href="https://wa.link/ovtot3" target="_blank">
          <img src="~@/assets/imgs/whatsapp.svg" alt="whatsapp" width="28px">
        </a>
      </div>
    </transition>
    <div class="chat-action">
      <a href="javascript:;" @click="toggleChat">
        <transition name="fade">
          <b-icon-chat v-if="!chatOpened"/>
          <b-icon-x v-if="chatOpened"/>
        </transition>
      </a>
    </div>
    <div class="call-action">
      <a href="tel:+212662799529">
        <b-icon-telephone-forward/>
      </a>
    </div>
    <tq-footer/>
  </div>
</template>

<style lang="scss">

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .chat-messenger {
    z-index: 999;
    border: 2px solid #fff;
    position: fixed;
    bottom: 230px;
    right: 20px;
    border-radius: 100%;
    padding: 13px;
    background: linear-gradient(rgb(255, 143, 178) 0%, rgb(167, 151, 255) 50%, rgb(0, 229, 255) 100%);
  }

  .chat-whatsapp {
    z-index: 999;
    border: 2px solid #fff;
    position: fixed;
    bottom: 160px;
    right: 20px;
    background-color: #25D366;
    border-radius: 100%;
    padding: 13px;
  }

  .chat-message {
    z-index: 999;
    background-color: #fff;
    border: 2px solid #393939;
    border-radius: 50px;
    padding: 10px 25px;
    position: fixed;
    bottom: 97px;
    right: 90px;
  }

  .content {
    margin-top: 80px;
    overflow-x: hidden;
  }

  .chat-action {
    z-index: 999;
    border: 2px solid #fff;
    position: fixed;
    bottom: 90px;
    right: 20px;
    background-color: #FFC440;
    border-radius: 100%;
    padding: 15px;
    svg {
      font-size: 24px;
      color: #000;
    }
  }

  .call-action {
    z-index: 999;
    border: 2px solid #fff;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: black;
    border-radius: 100%;
    padding: 15px;
    svg {
      font-size: 24px;
      color: #fff;
    }
  }
</style>

<script>
import TqHeader from '@/components/TqHeader.vue'
import TqFooter from '@/components/TqFooter.vue'
export default {
  methods: {
    toggleChat() {
      this.chatOpened = !this.chatOpened;
    }
  },
  data () {
    return {
      showSearchBlock: true,
      chatOpened: false,
    }
  },
  name: 'ALTHIQA-WEB',
  components: {
    TqHeader,
    TqFooter,
  }
}
</script>
