<template>
  <div class="home" id="top">
    <!-- Slide -->
    <div class="main-slider fullwidth-bg d-none d-lg-block">
      <b-container>
        <b-row>
          <b-col cols="7" class="pt-5 bring-to-front">
            <span class="slogon-text">ALTHIQA Fulfillement</span>
            <h1 class="main-headline mt-4">
              Althiqua livre pour vous,<br> exploitez votre temps pour l’essentiel
            </h1>
            <b-row class="mt-4">
              <b-col cols="4">
                <div class="action-button-wrapper">
                  <div class="hint-action-button">
                    Vous êtes déjà un client ?
                  </div>
                  <tq-button to="http://seller.althiqa.ma/login" target="_blank">Se connecter</tq-button>
                </div>
              </b-col>
              <b-col cols="6">
                <div class="action-button-wrapper">
                  <div class="hint-action-button">Nouveau client ?</div>
                  <tq-action-button to="http://forms.althiqa.ma/leads">Rejoignez-nous</tq-action-button>
                  <div class="hint-action-button">
                    Livraison à partir de 25.00DH <sup>ttc</sup>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <tq-video-holder class="slide-video"></tq-video-holder>
          </b-col>
        </b-row>
      </b-container>
    </div>


    <!-- mobile slider -->
    <div class="fullwidth-bg-mobile d-xs-block d-sm-block d-md-block d-lg-none">
      <b-container>
        <b-row class="margin-mobile pt-5 text-center">
          <b-col lg="12">
            <span class="slogon-text">ALTHIQA Fulfillement</span>
          </b-col>
          <b-col lg="12">
            <h1 class="main-headline mt-4">
              Althiqua livre pour vous,<br> exploitez votre temps pour l’essentiel
            </h1>
          </b-col>
          <b-col lg="12">
            <div class="action-button-wrapper">
              <div class="hint-action-button">
                Vous êtes déjà un client ?
              </div>
              <tq-button to="https://seller.althiqa.ma/login">Se connecter</tq-button>
            </div>
          </b-col>
          <b-col lg="12">
            <div class="action-button-wrapper">
              <div class="hint-action-button">Nouveau client ?</div>
              <tq-action-button to="http://forms.althiqa.ma/leads">Rejoignez-nous</tq-action-button>
              <div class="hint-action-button">
                Livraison à partir de 20.9DH <sup>ht</sup>
              </div>
            </div>
          </b-col>
          <b-col lg="12" class="mt-5 p-0">
            <tq-video-holder class="slide-video-mobile"></tq-video-holder>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <b-container>
      <b-row class="block margin-mobile">
        <b-col sm="12" md="5">
          <img
            src="~@/assets/imgs/house_car_splash.svg"
            alt="House car splash"
            width="100%"
            class="mb-3"/>
        </b-col>
        <b-col sm="12" md="7">
          <h2 class="big-title">
            <strong>
              Laissez ALTHIQA collecter, préparer, et livrer vos colis
            </strong>
          </h2>
          <p class="dark-text mt-4">
            ALTHIQA gère la collecte, la préparation et la livraison de  vos colis.<br>
            Avec ses solutions logistiques adaptées au marché marocain, une technologie de pointe, un personnel
            qualifié et un équipement moderne (systèmes WMS, scanner volume, voiture équipée et robot de triage)
            Althiqa vous garrantit une approche logistique innovante.
          </p>
          <tq-button to="/" class="mt-4 mb-4">
            <b-icon-play class="button-icon" /> Voir la video
          </tq-button>
        </b-col>
      </b-row>
    </b-container>

    <!-- gradiant background -->
    <div class="gradiant-background">
      <b-container>
        <!-- Get started with ALTHIQA block -->
        <b-row class="block-shadow block margin-mobile side-splash">
          <b-col>
            <h2 class="big-title mt-5" id="start">
              <strong> Démarrer avec ALTHIQA </strong>
            </h2>
            <p class="mt-4 mb-4 ">
              ALTHIQA FULFILLEMENT vous soulage des soucis liés à la logistique,<br>
              vous permettant de concentrer sur l’évolution de votre projet.
            </p>

            <!-- Guide download's link -->
            <router-link to="/403" class="download-link">
              <strong>Télécharger le Guide de démarrage</strong>
              <b-icon-download class="download-icon" />
            </router-link>

            <!-- Steps block -->
            <div class="steps-block">
              <tq-step title="1ère étape" step="Préparer votre compte ALTHIQA">
                <a href="seller.althiqa.ma/signup" target="_blank" class="link">Créer votre compte</a>, et
                <a href="seller.althiqa.ma/authentication" target="_blank" class="link">connectez-vous</a> à votre espace vendeur pour <router-link to="/403" class="link">préparer votre
                compte ALTHIQA</router-link>.
              </tq-step>
              <tq-step
                title="2ème étape"
                step="Installer le plugin Livraison ALTHIQA"
              >
                Quand vous disposez d’un <router-link to="/403" class="link">compte actif</router-link>, installez <router-link to="/403" class="link">l'extension
                Livraison ALTHIQA</router-link> compatible avec votre plateforme, <router-link to="/403" class="link">voir des
                solutions alternatives</router-link>.
              </tq-step>
              <tq-step
                title="3ème étape"
                step="Configurer votre plugin Livraison ALTHIQA"
              >
                Après l’installation de l'extension vous devez la configurer avec les
                informations de votre compte.
              </tq-step>
              <tq-step title="4ème étape" step="Évoluer votre projet">
                Concentrez-vous sur l’évolution de votre projet et laisser ALTHIQA
                faire le reste.
              </tq-step>
            </div>

            <!-- Guide terms -->
            <p class="small-text">
              Quand ALTHIQA reçoit les informations du récepteur, les colis
              seront trackable.<br />
              Visiter <router-link to="/403" class="link">l’espace du support</router-link> pour
              avoir plus d’informations sur la
              <router-link to="/403" class="link">gestion du tracking</router-link>.
            </p>
          </b-col>
        </b-row>

        <!-- Seller stoplight -->
        <b-row class="block" v-if="false">
          <b-col cols="6"> </b-col>
          <b-col cols="6">
            <h6 class="slogon-text highlighted-text mb-3">
              VENDEUR SPOTLIGHT: YAMAT HAMDOLAH
            </h6>
            <img
              src="~@/assets/imgs/quote-left-solid.svg"
              alt="''"
              width="60px"
            />
            <blockquote class="quote">
              As long as I have my tools and materials,<br />
              I can depend on ALTHIQA to keep my income constant.
            </blockquote>
            <b-row>
              <b-col cols="2">
                <img src="~@/assets/imgs/salah.png" alt="salah" width="100%" />
              </b-col>
              <b-col class="no-padding">
                <b-row class="mt-3">
                  <b-col>
                    <h6 class="no-margin">
                      <strong> SALAH EDDINE ALAMJED </strong>
                    </h6>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <p class="small-text">YAMAT HAMDOLAH</p>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <!-- Advanteges blocks -->
        <b-row class="margin-mobile">
          <b-col class="text-center pt-4">
            <h2 class="big-title mb-2">
              <strong> Les avantages d’ALTHIQA </strong>
            </h2>
          </b-col>
        </b-row>
        <b-row class="block block-shadow margin-mobile">
          <b-col md="2" class="mb-4">
            <img src="~@/assets/imgs/car_check.svg" class="mt-2-mobile" alt="" />
          </b-col>
          <b-col md="9">
            <h3>
              <strong>
                Livraison en temps record + échange gratuit
              </strong>
            </h3>
            <p class="mt-3 small-text-mobile">
              Avec le service Warehousing d’ALTHIQA, vous bénéficiez d’une solution
              logistique complète de A à Z, et une option pour un échange gratuit.<br>
              Les articles éligibles et qualifiés seront marqués d’un badge pour
              une reconnaissance facile par vos clients.
            </p>
          </b-col>
        </b-row>
        <b-row class="block block-shadow margin-mobile">
          <b-col md="2">
            <img src="~@/assets/imgs/headphones.svg" class="mt-2-mobile" alt="" />
          </b-col>
          <b-col md="9">
            <h3>
              <strong>Relation clientèle ALTHIQA</strong>
            </h3>
            <p class="mt-3 small-text-mobile">
              ALTHIQA mise sur un centre d’appel moderne et une
              expérience clients de qualité pour assurer de bonnes relations clientèles.<br>
              Prenez soin d’interagir avec note centre clientèle.
            </p>
            <p class="small-text-mobile">
              <strong>
                Relation client:
              </strong>
               Vous pouvez nous contacter par téléphone ou par e-mail pour consulter
               l’état de vos livraisons, demander un échange, initialiser un retour,
               un service sans frais supplémentaires.</p>
            <p class="small-text-mobile">
              <strong>
                Feedback:
              </strong>
              Le service client gère, en sus, le traitement des feedbacks clients via
              notre espace client.
            </p>
          </b-col>
        </b-row>
        <b-row class="block block-shadow margin-mobile">
          <b-col md="2">
            <img src="~@/assets/imgs/chart.svg" class="mt-2-mobile" alt="" />
          </b-col>
          <b-col md="9">
            <h3 class="mt-1-mobile">
              <strong>
                Althiqa vous accompagne à développer votre business
              </strong>
            </h3>
            <p class="mt-3 small-text-mobile">
              Le service d’expédition ALTHIQA est une solution flexible,
              adapté à vos objectifs et offrant plusieurs options pour
              expédier toutes les commandes de vos clients dans tout le royaume
            </p>
            <p class="small-text-mobile">
              <strong>
                Produit petit et léger :
              </strong>
              Vous profitez de réduction et des bas prix sur les frais d'expédition
              pour les commandes des produits petits, légers et  de votre stock
              expédié par Althiqa.
            </p>
            <p class="small-text-mobile">
              <strong>
                Ventes multi-sites :
              </strong>
              Avec le plugin et API dédiés, vous pouvez vendre sur vos propres
              sites e-commerce ou sur des plateformes tierces, tout en profitant
              des services de préparation et d’expédition d’ALTHIQA.
            </p>
            <p class="small-text-mobile">
              <strong>
                Produits lourds et Volumineux :
              </strong>
              Utilisez “Expédié par Althiqa” comme solution pour le stockage,
              l’expédition, les retours et le service client de vos produits
              lourds et volumineux.
            </p>
          </b-col>
        </b-row>
        <b-row class="block block-shadow no-margin-bottom margin-mobile">
          <b-col md="2">
            <img src="~@/assets/imgs/hand_coin.svg" class="mt-2-mobile" alt="" />
          </b-col>
          <b-col md="9">
            <h3 class="mt-1-mobile" >
              <strong> Optimisation financière </strong>
            </h3>
            <p class="mt-3 small-text-mobile">
              ALTHIQA vous propose une solution à double avantages:
            </p>
            <ul class="ml-3">
              <li>Économiser sur vos factures logistiques</li>
              <li>Fdélisez vos clients avec notre service de livraison irréprochable.</li>
            </ul>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!-- Gradiant invert background -->
    <div class="inverted-gradiant-background">
      <b-container>
        <b-row class="pt-5">
          <b-col class="text-center">
            <h2 class="big-title mt-5">
              <strong> Tarif Warehousing, Expédition et Préparation </strong>
            </h2>
          </b-col>
        </b-row>
        <b-row class="mt-5 margin-mobile">
          <b-col md="6">
            <h3>
              <strong> Tarif de stockage </strong>
            </h3>
            <p class="mt-3 small-text-mobile">
              Évaluer précisément les besoins de stockage est essentiel,
              sans oublier que l'entreposage représente un coût non négligeable
              pour une entreprise. Chez Althiqa, les coûts de stockage dépendent
              de la nature de l’article (fragile, inflammable…) et de ses dimensions.
              <router-link to="/403" class="link">Découvrez comment
              déterminer la taille du produit pour vos articles.</router-link>
            </p>
          </b-col>
          <b-col md="6">
            <h3 class="mt-2-mobile">
              <strong>Tarif de stockage longue durée</strong>
            </h3>
            <p class="mt-3 small-text-mobile">
              Évaluer chaque mois pour tous les articles stockés pendant plus de 365
              jours dans un centre de distribution. Une bonne gestion de votre inventaire
              peut vous aider à éviter les frais de stockage longue durée.<br><router-link to="/403" class="link">
              Découvrez comment déterminer le prix de stockage longue durée</router-link>
            </p>
          </b-col>
        </b-row>
        <b-row class="mt-3 margin-mobile">
          <b-col md="6">
            <h3 class="mt-2-mobile">
              <strong> Tarif de préparation </strong>
            </h3>
            <p class="mt-3 small-text-mobile">
              Le coût de préparation des articles varie en fonction de plusieurs critères, tels que les
              dimensions et la nature de l’article (Fragile, inflammable…).
              <br><router-link to="/403" class="link">
              Découvrez comment déterminer le prix de préparation pour vos articles.</router-link>
            </p>
          </b-col>
          <b-col md="6">
            <h3 class="mt-2-mobile">
              <strong>Tarif des services non planifiés</strong>
            </h3>
            <p class="mt-3 small-text-mobile">
              Facturé lorsque le stock arrive dans un centre de distribution sans préparation ou étiquetage approprié
              ou lorsque le vendeur demande un ramassage, entraînant des services non planifiés.
              <br><router-link to="/403" class="link">
              Découvrez comment déterminer le prix des services non planifiés </router-link>
            </p>
          </b-col>
        </b-row>
        <b-row class="mt-3 margin-mobile">
          <b-col md="6">
            <h3 class="mt-2-mobile">
              <strong> Tarif d’annulation des livraisons</strong>
            </h3>
            <p class="mt-3 small-text-mobile">
              Vous pouvez annuler gratuitement une livraison soit via le gestionnaire soit
              en appelant notre centre d’appel pour le prendre en charge.
              <br><router-link to="/403" class="link">
              Découvrez comment déterminer le prix d'annulation de la livraison selon la phase où elle se trouve </router-link>
            </p>
          </b-col>
          <b-col md="6">
            <h3 class="mt-2-mobile">
              <strong>Tarif de traitement des retours</strong>
            </h3>
            <p class="mt-3 small-text-mobile">
              Le service de retour Althiqa simplifie la gestion des retours avec des avantages concurrentiels.
              Sous certaines conditions vos retours peuvent être gratuits.
              peuvent être gratuit.<br><router-link to="/403" class="link">
              Découvrez comment déterminer le prix de traitement des retours.</router-link>
            </p>
          </b-col>
        </b-row>
        <b-row class="mt-3 margin-mobile">
          <b-col md="6">
            <h3 class="mt-2-mobile">
              <strong>Tarif d'expédition</strong>
            </h3>
            <p class="mt-3 small-text-mobile">
              Althiqa assure l’envoi rapide, express et sécurisé de vos colis.
              Les tarifs d’expédition dépendent de divers facteurs. Pour une bonne estimation,
              <br>
              <router-link to="/403" class="link">
              Utilisez le simulateur Althiqa</router-link>
              pour obtenir une estimation et un aperçu des frais.

            </p>
          </b-col>
          <b-col md="6">
            <h3 class="mt-2-mobile">
              <strong>Tarif de commande de prélèvement</strong>
            </h3>
            <p class="mt-3 small-text-mobile">
              Pour retourner ou liquider votre stock dans un centre de distribution,
              des frais par article sont appliqués.
              <br><router-link to="/403" class="link">
              Découvrez comment déterminer le prix de commande prélèvement</router-link>
            </p>
          </b-col>
        </b-row>
        <b-row class="mt-5 mb-5 margin-mobile">
          <b-col>
            <p class="small-text text-center">
              Savoir plus sur la tarification ALTHIQA. <router-link to="/403" class="link">Utiliser le calculateur
              de tarification</router-link> pour éstimer vos frais.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-container>
      <b-row class="margin-mobile">
        <b-col class="text-center top-separator">
        </b-col>
      </b-row>
      <b-row class="margin-mobile">
        <b-col class="text-center">
          <h2 class="big-title">
            <strong>Gestion des envois</strong>
          </h2>
          <p class="mt-4">
            L’espace vendeur offre plusieurs outils pour créer, gérer vos plans de livraison, envoyer vos colis,
            suivre vos commandes et avoir un aperçu de votre logistique en simple clics.
          </p>
          <p class="mt-4">
            <router-link to="/403" class="forword-link">
              <strong>En savoir plus sur la gestion des envois<b-icon-arrow-right-short class="link-icon"
              /></strong>
            </router-link>
          </p>
        </b-col>
      </b-row>
      <b-row class="mt-5 mb-5 pt-2 spyscroll-block margin-mobile">
        <b-col cols="4" class="d-none d-xl-block">
          <div class="spyscroll-menu" :class="isFixed">
            <router-link to="#spy-scroll-link-1" v-for="title, i in spyScrollTitles" :key="title" class="no-style-link mt-3" :class="{active:i == currentSpyScrollTitles}" >{{ title }}</router-link>
          </div>
        </b-col>
        <b-col class="mt-3">
          <b-row>
            <b-col>
              <h3 class="spyscroll-title" id="spy-scroll-link-1">
                <strong> Tableau de bord des performances des envois </strong>
              </h3>
              <p class="mt-3">
                Ce tableau de bord vous aide à analyser les performances de dérivabilités par jour et vous donne
                une vision globale sur votre stratégie logistique.
              </p>
            </b-col>
          </b-row>

          <b-row class="mt-5 pt-5">
            <b-col>
              <h3 class="spyscroll-title">
                <strong> Gestionnaire des envois </strong>
              </h3>
              <p class="mt-3">
                Le gestionnaire vous aide à vérifier le statut et le suivi des commandes, annuler
                des articles ou des commandes, modifier des informations relatives à la commande,
                consulter l’historique des commandes…
              </p>
            </b-col>
          </b-row>

          <b-row class="mt-5 pt-5">
            <b-col>
              <h3 class="spyscroll-title">
                <strong>Contre remboursement (COD) et déclaration sur la valeur</strong>
              </h3>
              <p class="mt-3">
                Notre service de contre remboursement (COD) vous permet de vendre à des clients
                sans leur accorder de crédit ni demander de paiement anticipé. Le service contre
                remboursement (COD) est disponible pour tous vos colis avec la flexibilité
                d’inclure nos tarifs.
              </p>
              <p>
                Déclaration sur la valeur : Nous vous recommandons cette protection complète pour vos
                envois à haute valeur marchande, ce qui vous permet d'avoir l'esprit tranquille en
                cas de dommages ou de pertes physiques
              </p>
              <b-row class="mt-4">
                <b-col md="6">
                  <horizontal-video class="money" question="C'est quoi le service contre rembourcement (COD) ?"> </horizontal-video>
                </b-col>
                <b-col md="6" class="mt-3-mobile">
                  <horizontal-video class="declation" question="C'est quoi la déclaration sur la valeur ?"> </horizontal-video>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="mt-5 pt-5">
            <b-col>
              <h3 class="spyscroll-title">
                <strong>Retours et Envois annulé</strong>
              </h3>
              <p class="mt-3">
                Retour : Le service retour vous permet de gérer facilement, efficacement et à moindre coût,
                vos retours tout en assurant la satisfaction de vos clients grâce à la rapidité
                et la simplicité du service.
                Avec le service “retour” d’Althiqa, vos clients peuvent préparer des retours, puis il suffit de
                remettre les colis de retour dans une agence ALTHIQA une fois la procédure terminée,
              </p>
              <p>
                Envoi annulé : Après trois tentatives de livraison, Althiqa conservera votre envoi
                pendant cinq jours ouvrables dans une agence Althiqa la plus proche. Si l'envoi n'est
                pas enlevé au cours de ces cinq jours ouvrables, il sera retourné à l'expéditeur.
              </p>
              <b-row class="mt-4">
                <b-col md="6">
                  <horizontal-video class="return" question="C'est quoi le retour ?"> </horizontal-video>
                </b-col>
                <b-col md="6" class="mt-3-mobile">
                  <horizontal-video class="abord" question="C'est quoi l'envoi annulé ?"> </horizontal-video>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/styles/variables";

.slide-video {
  position: absolute;
  top: 100px;
  right: 25px;
  z-index: 1;
  width: 430px;
}

.money {
  background: url('https://afrique.lalibre.be/app/uploads/2018/01/o-DIRHAM-facebook.jpg') center/cover;;
}

.declation {
  background: url('~@/assets/imgs/reception-dh.jpg') center/cover;;
}

.return {
  background: url('https://i.ytimg.com/vi/JMW-YbR0mzE/maxresdefault.jpg') center/cover;
}

.abord {
  background: url('https://images.cisco-eagle.com/blog/wp-content/uploads/2019/02/Warehouse-Safety.jpeg') center/cover;
}

.steps-block {
  margin: 50px 0 10px;
}

.bring-to-front {
  z-index: 1;
}

.fullwidth-bg {
  background-color: #ffc4401a;
  height: 280px;
  margin-bottom: 250px;
}

.fullwidth-bg::after {
  content: "";
  position: absolute;
  background-image: url(~@/assets/imgs/wave_bottom_block.svg);
  width: 100%;
  height: 176px;
  top: 360px;
}

.main-headline {
  font-size: 39.5px;
  font-weight: bold;
  margin: 15px 0;
}

@media ( min-width: 1400px ) {
  .side-splash {
    position: relative;
  }
  .side-splash:after {
    content: "";
    position: absolute;
    background-image: url(~@/assets/imgs/car_truck_operator_splash.svg);
    height: 353px;
    width: 900px;
    top: 50px;
    right: -600px;
  }
}
@media ( max-width: 1400px ) {
  .fullwidth-bg-mobile {
    background-color: #ffc4401a;
    height: 610px;
    margin-bottom: 350px;
  }

  .fullwidth-bg-mobile::after {
    content: "";
    position: absolute;
    background-image: url(~@/assets/imgs/wave_bottom_block.svg);
    width: 100%;
    height: 176px;
    top: 667px;
    left: 0;
  }

  .action-button-wrapper {
    width: 100%;
  }
}

.gradiant-background {
  background-image: linear-gradient(0deg, #fff9eb, #fff);
  position: relative;
  z-index: 99;
}

.inverted-gradiant-background {
  background-image: linear-gradient(180deg, #fff9eb, #fff);
  position: relative;
  z-index: 98;
}

.top-separator {
  padding-top: 80px;
  margin-left: 100px;
  margin-right: 100px;
  border-top: 1px solid #eaeded;
}

.fixed-menu {
  position: fixed;
  top: 150px;
  width: 380px;
}

.end-scroll-menu {
  position: absolute;
  bottom: 0;
  width: 380px;
}

.spyscroll-title {
  transition: color .15s cubic-bezier(.64,.15,.53,.53);
}
</style>

<script>
import { BIconPlay, BIconDownload } from "bootstrap-vue";
import TqActionButton from "../components/TqActionButton.vue";
import TqButton from "../components/TqButton.vue";
import TqVideoHolder from "../components/TqVideoHolder.vue";
import TqStep from "../components/TqStep.vue";
import HorizontalVideo from "../components/HorizontalVideo.vue";
export default {
  methods: {
    handleScroll() {
      const els = document.querySelectorAll(".spyscroll-block");
      els.forEach((el) => {
        const elTop = el.getBoundingClientRect().top;
        const elBottom = el.getBoundingClientRect().bottom;
        if (elTop >= 150) {
          this.isFixed = "";
        }
        if (elTop <= 150) {
          this.isFixed = "fixed-menu";
        }
        if (elBottom <= 350) {
          this.isFixed = "end-scroll-menu";
        }
      });
    },
    spyScroll() {
      const els = document.querySelectorAll(".spyscroll-title");
      els.forEach((el, index) => {
        const elTop = el.getBoundingClientRect().top;
        const elBottom = el.getBoundingClientRect().bottom;
        if (elTop <= 150 && elBottom >= 0) {
          this.currentSpyScrollTitles = index;
          console.log(index);
        }
      });
    },
    scrollToTop() {
      window.scrollTo(0,0);
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('scroll', this.spyScroll);
  },
  mounted () {
    this.scrollToTop()
  },
  data() {
    return {
        isFixed: "",
        spyScrollTitles:[
          "Tableau de bord des performances des envois",
          "Gestionnaire des envois",
          "Contre remboursement (COD) et déclaration sur la valeur",
          "Retours et Envois annulé"],
        currentSpyScrollTitles: 0,
    };
  },
  name: "Accueil",
  components: {
    TqActionButton,
    TqButton,
    TqVideoHolder,
    BIconPlay,
    BIconDownload,
    TqStep,
    HorizontalVideo,
  },
};
</script>
