<template>
    <a :href="to" class="action-button" target="_blank">
        <slot></slot>
    </a>
</template>

<style lang="scss" scoped>
    @import "~@/assets/styles/variables";
    .action-button {
        display: inline-block;
        color: $text-primary-color;
        padding-top: 10px !important;
        background: $primary;
        border-radius: 50px;
        border: 0;
        height: 45px;
        box-shadow: 0px 0px 30px #00000029;
        margin: 1px 0 0;
        padding: 0 30px;
        font-weight: bold;
        transition-duration: .5s;
    }

    .action-button:hover {
        box-shadow: 0px 0px 30px #00000048;
        transition-duration: .5s;
        text-decoration: none;
    }
</style>

<script>
export default {
  props: {
      to: String,
  },

}
</script>