import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Network from '../views/Network.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Accueil',
    meta: { title: 'Accueil' },
    component: Home
  },
  {
    path: '/tarification',
    name: 'Tarification',
    meta: { title: 'Tarification' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Pricing.vue'),
  },
  {
    path: '/network',
    name: 'network'   ,
    meta: {title: 'network'},
    component: Network,
  },

  {
    path: '/403',
    name: '403',
    meta: { title: '403' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/403.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector:to.hash,
        behavior: 'smooth',
        offset: {
          x:0,
          y:150
        }
      }
    }
  },
})

const DEFAULT_TITLE = 'Livraison ALTHIQA';
router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});

export default router
