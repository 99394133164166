<template>
    <div class="footer-block">
        <div class="top-footer">
            <b-container>
                <b-row class="margin-mobile">
                    <b-col>
                        <h2 class="big-title text-center-mobile">
                            <strong>
                                ALTHIQA,<br> La meilleure solution pour le E-commerce
                            </strong>
                        </h2>
                    </b-col>
                </b-row>
                <b-row class="mt-4 margin-mobile">
                    <b-col lg="3">
                        <div class="action-button-wrapper text-center-mobile">
                            <div class="hint-action-button">Vous êtes déjà un client ?</div>
                            <tq-button to="https://seller.althiqa.ma/inscription">Se connecter</tq-button>
                        </div>
                    </b-col>
                    <b-col lg="3">
                        <div class="action-button-wrapper text-center-mobile">
                            <div class="hint-action-button">Nouveau client ?</div>
                            <tq-action-button to="https //seller.althiqa.ma/inscription">Rejoignez-nous</tq-action-button>
                            <div class="hint-action-button">
                            Livraison à partir de 13DH <sup>ht</sup>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <div class="footer">
            <b-container>
                <b-row class="footer-top footer-separation margin-mobile">
                    <b-col class="text-right">
                        <span class="lang white-text mr-3">
                            <b-icon-globe2/> Langue
                        </span>
                        <span class="feedback white-text mr-3">
                            <img src="~@/assets/imgs/moroccan_flag.svg" alt="Maroc" class="flag">
                            Maroc
                        </span>
                        <span class="feedback white-text">
                            <b-icon-star-fill/> FeedBack
                        </span>
                    </b-col>
                </b-row>
                <b-row class="footer-body footer-separation margin-mobile">
                    <b-col cols="6" md="3" class="p-0">
                        <ul class="white-text">
                            <li>
                                <strong class="white-text">
                                    Livrer avec ALTHIQA
                                </strong>
                            </li>
                            <li>
                                <router-link to="/" class="footer-link">
                                    Combien ça coûte?
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/" class="footer-link">
                                    Guide de démarrage
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/" class="footer-link">
                                    Gestion d'inventaire
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/" class="footer-link">
                                    Gestion des ramassages
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/" class="footer-link">
                                    Intégration d'extention
                                </router-link>
                            </li>
                        </ul>
                    </b-col>
                    <b-col cols="6" md="3" class="p-0">
                        <ul class="white-text">
                            <li>
                                <strong class="white-text">
                                    ALTHIQA pour les businesses
                                </strong>
                            </li>
                            <li>
                                <router-link to="/" class="footer-link">
                                    Intégration verticale
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/" class="footer-link">
                                    Emballage personalisé
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/" class="footer-link">
                                    Solutions de stockage
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/" class="footer-link">
                                    Solutions piods lourd
                                </router-link>
                            </li>
                        </ul>
                    </b-col>
                    <b-col cols="6" md="3" class="p-0">
                        <ul class="white-text">
                            <li>
                                <strong class="white-text">
                                    Ressources de demarrage
                                </strong>
                            </li>
                            <li>
                                <router-link to="/" class="footer-link">
                                    Simulateur de tarif
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/" class="footer-link">
                                    Université ALTHIQA
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/" class="footer-link">
                                    Reséau ALTHIQA
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/" class="footer-link">
                                    Événements
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/" class="footer-link">
                                    Centre de support des vendeurs
                                </router-link>
                            </li>
                        </ul>
                    </b-col>
                    <b-col cols="6" md="3" class="p-0">
                        <ul class="white-text">
                            <li>
                                <strong class="white-text">
                                    Liens utiles
                                </strong>
                            </li>
                            <li>
                                <router-link to="/" class="footer-link">
                                    Connectez-vous à votre esapce
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/" class="footer-link">
                                    Optimiser votre solution logistique
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/" class="footer-link">
                                    Comment évoluer mon E-commerce?
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/" class="footer-link">
                                    Carrières
                                </router-link>
                            </li>
                        </ul>
                    </b-col>
                </b-row>
                <b-row class="bottom-footer margin-mobile">
                    <b-col cols="5" class="p-0">
                        <router-link to="/">
                            <img src="~@/assets/imgs/althiqa_logo_arabic_right.svg" alt="ALTHIQA" width="100px">
                        </router-link>
                    </b-col>
                    <b-col class="text-right p-0">
                        <router-link to="/" class="footer-link small-text mr-4">
                            Conditions d'utilisation
                        </router-link>
                        <router-link to="/" class="footer-link small-text mr-5">
                            Confidentialité
                        </router-link>
                        <span class="footer-link small-text">
                            © 2021, Service Nobel-consulting.com SARL
                        </span>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/styles/variables";

.footer-block {
  margin-top: 100px;
}

.top-footer {
    padding-top: 200px;
    background: url("~@/assets/imgs/car_truck_operator_splash.svg") bottom 50px
        right/750px no-repeat,
        url("~@/assets/imgs/clouds_splash.svg") center right 20%/550px no-repeat,
        url("~@/assets/imgs/tq-wave-horizon-1.svg") top center/cover no-repeat;
    height: 559px;
}

.footer {
    background-color: black;
    font-size: 14px;
}

.footer-top {
    padding-top: 30px;
    padding-bottom: 30px;
}

.footer-separation {
    border-bottom: 2px solid $text-primary-light-color;
}

.footer-body {
    padding: 30px 0;

    ul {
        list-style: none;
        padding: 0;
    }
}

.flag {
    width: 22px;
    margin-right: 5px;
    border-radius: 2px;
    vertical-align: -3px;
}

.footer li {
    margin: 8px 0;
}

.footer-link {
    text-decoration: none;
    color: $text-primary-light-color;
}

.bottom-footer {
    padding: 30px 0 40px;
}

@media (max-width: 1400px) {
    .top-footer {
        padding-top: 120px !important;
        background: url("~@/assets/imgs/tq-wave-horizon-1.svg") top center/cover no-repeat !important;
        height: 559px;
    }
}
</style>

<script>
    import { BIconGlobe2, BIconStarFill } from "bootstrap-vue";
    import TqActionButton from "../components/TqActionButton.vue";
    import TqButton from "../components/TqButton.vue";
    export default {
        components: {
            TqActionButton,
            TqButton,
            BIconGlobe2,
            BIconStarFill,
        }
    }
</script>