import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vSelect from 'vue-select'
import VueGtag from "vue-gtag";

//Bootstrap
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import './assets/styles/app.scss'

Vue.config.productionTip = false

//Bootstarp
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

//components
Vue.component('v-select', vSelect)

Vue.use(VueGtag, {
  config: { id: "G-MZEB3JK6F6" },
  appName: 'ALTHIQA WEB',
  pageTrackerScreenviewEnabled: true
}, router);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
