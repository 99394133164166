<template >
  <div>
    <a
      href="javascript:;"
      class="search-button"
      @click="showSearch"
    >
      <b-icon-search />
    </a>
  </div>
</template>

<style lang="scss" scoped>
    @import "~@/assets/styles/variables";
    .search-button {
        margin: 8px 0 0 30px;
        color: $light-grey;
        font-size: 20px;
        transition-duration: .2s;
    }

    .search-button:hover {
        color: $primary;
        transition-duration: .2s;
    }
</style>

<script>
import { BIconSearch } from "bootstrap-vue";
export default {
  data() {
    return {
      searchVisbility: false,
    };
  },
  methods: {
    showSearch() {
      this.$root.$emit('showSearch');
    },
  },
  components: {
    BIconSearch,
  },
};
</script>