<template>
    <a href="javascript:;">
        <div class="video-holder">

        </div>
    </a>
</template>

<style lang="scss" scoped>
    @import "~@/assets/styles/variables";
    .video-holder {
        position: absolute;
        background-color: #8AD5E2;
        border-radius: 7px;
        box-shadow: 0px 0px 30px #00000029;
        z-index: 1;
        width: 430px;
        height: 241px;
        transition: transform .3s cubic-bezier(.64,.15,.53,.53),-webkit-transform .3s cubic-bezier(.64,.15,.53,.53);
    }

    @media (max-width: 992px) {
        .video-holder {
            position: relative !important;
            z-index: 1;
            width: 100% !important;
            height: 241px;
        }

        .video-holder::after {
            left: 0;
            background-position: 50% !important;
        }
    }

    .video-holder:hover {
        -webkit-transform: scale(1.03);
        -ms-transform: scale(1.03);
        transform: scale(1.03);
        transition: transform .3s cubic-bezier(.64,.15,.53,.53),-webkit-transform .3s cubic-bezier(.64,.15,.53,.53);
    }

    .video-holder::after {
        content: "";
        background: url("~@/assets/imgs/play_button.svg") center no-repeat, url("~@/assets/imgs/althiqa-day.jpg") center/cover no-repeat;
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 7px;
    }
</style>

<script>
export default {

}
</script>