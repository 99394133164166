<template>
    <div class="header">
        <tq-navbar>
            <tq-navbar-dropdown title="Démarrer" to="#startup-guide">
                <tq-navbar-dropdown-item to="#startup-guide">
                    Guide de démarrage
                </tq-navbar-dropdown-item>
                <tq-navbar-dropdown-item to="/comment-se-fait-une-livraison">
                    Comment se fait une livraison avec ALTHIQA
                </tq-navbar-dropdown-item>
                <tq-navbar-dropdown-item to="/integer-avec-votre-systeme">
                    Inégrer les services ALTHIQA avec votre système
                </tq-navbar-dropdown-item>
                <tq-navbar-dropdown-item to="/configurer-votre-programme-de-livraison">
                    Configurer votre programme de livraison
                </tq-navbar-dropdown-item>
              <tq-navbar-dropdown-item to="/network" >
                 Réseau Althiqa
              </tq-navbar-dropdown-item>
            </tq-navbar-dropdown>
            <tq-navbar-dropdown title="Évoluer" to="/x" v-if="false">
                <tq-navbar-dropdown-item to="test">
                    Réduisez votre facture de livraison
                </tq-navbar-dropdown-item>
                <tq-navbar-dropdown-item to="test">
                    Optimisez votre espace de stockage
                </tq-navbar-dropdown-item>
                <tq-navbar-dropdown-item to="test">
                    Couverture nationale
                </tq-navbar-dropdown-item>
                <tq-navbar-dropdown-item to="test">
                    Applications, Services et Outiles
                </tq-navbar-dropdown-item>
            </tq-navbar-dropdown>
            <tq-navbar-dropdown title="Apprendre" to="/y" v-if="false">
                <tq-navbar-dropdown-item to="test">
                    Université ALTHIQA
                </tq-navbar-dropdown-item>
                <tq-navbar-dropdown-item to="test">
                    Guide de démarrage
                </tq-navbar-dropdown-item>
                <tq-navbar-dropdown-item to="test">
                    ALTHIQA Fulfillment
                </tq-navbar-dropdown-item>
                <tq-navbar-dropdown-item to="test">
                    Comment gérer vos livraisons?
                </tq-navbar-dropdown-item>
            </tq-navbar-dropdown>

            <tq-navbar-dropdown title="Tarification" to="/tarification#top">
                <tq-navbar-dropdown-item to="/tarification#frais-de-livraison">
                    Frais de livraison avec ALTHIQA
                </tq-navbar-dropdown-item>
                <tq-navbar-dropdown-item to="comparatif-de-plans-de-livraison">
                    Comparatif de plans de livraison
                </tq-navbar-dropdown-item>
                <tq-navbar-dropdown-item to="test">
                    Simulateur de frais
                </tq-navbar-dropdown-item>
                <tq-navbar-dropdown-item to="test">
                    Autre frais
                </tq-navbar-dropdown-item>
            </tq-navbar-dropdown>

        </tq-navbar>
        <tq-navbar-search-block/>
    </div>
</template>

<script>
import TqNavbar from '@/components/TqNavbar.vue'
import TqNavbarDropdown from '@/components/TqNavbarDropdown.vue'
import TqNavbarDropdownItem from '@/components/TqNavbarDropdownItem.vue'
import TqNavbarSearchBlock from '@/components/TqNavbarSearchBlock.vue'

export default {
  name: 'ALTHIQA-Header',
  components: {

    TqNavbar,
    TqNavbarDropdown,
    TqNavbarDropdownItem,
    TqNavbarSearchBlock,
  }

}
</script>

<style lang="scss" scoped>
    .header {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 999;
    }
</style>