<!-- UserList.vue -->
<template>
  <b-container>
  <div class="Page pt-5" id="top">
        <h2>
          <strong>
            Réseau Althiqa
          </strong>
        </h2>

   <div class="block-shadow" >
     <b-row class="table-head">
       <b-col cols="5">
         <strong> Ville </strong>
       </b-col>
       <b-col cols="5">
         <strong> Zone </strong>
       </b-col>
       <b-col cols="2">
         <strong> Agence </strong>
       </b-col>
     </b-row>
    <b-row class="table-item mr-auto" v-for="city in Cities" :key="city.id" >

      <b-col  cols="5">
        <p class="text-nowrap small-text">{{ city.name }}</p>
      </b-col>

      <b-col  cols="5" v-if="city.state ==='A1'">
        <p class="text-nowrap small-text">A1</p>
      </b-col>

      <b-col  cols="5" v-else-if="city.state ==='A2'">
        <p class="text-nowrap small-text">A2</p>
      </b-col>

      <b-col  cols="5" v-else-if="city.state ==='A4'">
      <p class="text-nowrap small-text">A4</p>
    </b-col>

      <b-col  cols="5" v-else-if="city.state ==='A6'">
        <p class="text-nowrap small-text">A6</p>
      </b-col>

      <b-col  cols="5" v-else-if="city.state ==='A7'">
        <p class="text-nowrap small-text">A7</p>
      </b-col>

      <b-col  cols="5" v-else-if="city.state ==='A8'">
        <p class="text-nowrap small-text">A8</p>
      </b-col>

      <b-col  cols="5" v-else-if="city.state ==='A9'">
        <p class="text-nowrap small-text">A9</p>
      </b-col>

      <b-col  cols="2" v-for="servicePoint in city.servicePoint" :key="servicePoint.id">
        <p class="text-nowrap small-text">{{ city.servicePoint.name }}</p>
      </b-col>
     </b-row>
  </div>
  </div>
  </b-container>
</template>

<script>
import CityService from "@/services/CityService";
export default {

  data() {
    return {
      Cities: []
    };
  },
  mounted() {
    this.fetchPosts();
  },
  methods: {
    fetchPosts() {
      CityService.fetchPosts()
          .then(response => {
            this.Cities = response.data.data;
          })
          .catch(error => {
            console.error('Error fetching posts:', error);
          });
    }
  }
};
</script>


