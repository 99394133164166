<template>
    <a :href="to" class="action-button" target="_blank">
        <slot></slot>
    </a>
</template>

<style lang="scss" scoped>
    @import "~@/assets/styles/variables";
    .action-button {
        display: inline-block;
        color: $text-primary-color;
        background: white;
        border-radius: 50px;
        border: 3px solid $text-primary-color;
        height: 45px;
        margin: 1px 0 0 0px;
        padding: 7px 20px 0 20px;
        font-weight: bold;
        transition-duration: .5s;
    }

    .action-button:hover {
        box-shadow: 0px 0px 30px #00000048;
        transition-duration: .5s;
        text-decoration: none;
    }

    .button-icon {
        font-size: 24px;
        vertical-align: -0.27em !important;
    }
</style>

<script>
export default {
  props: {
      to: String,
  },

}
</script>