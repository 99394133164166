<template>
  <transition name="slide" v-if="searchVisbility">
    <div class="search-block-wrapper" id="search-block">
      <b-container class="search-block">
        <b-row>
          <b-col class="text-right">
            <button class="btn-transparent" @click="searchVisbility = false">
              <b-icon-x class="close-button" />
            </button>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <input type="text" class="search-field-text" ref="search"/>
            <b-icon-search class="search-icon"/>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col class="text-center">
            <h6>
              Quick links: <span class="question">Selling on ALTHIQA</span>
              <span class="question">Fulfillment by ALTHIQA</span>
            </h6>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
@import "~@/assets/styles/variables";
.search-block-wrapper {
  background-color: $primary;
  z-index: 0;
  position: fixed;
  z-index: 1;
  width: 100%;
  padding: 10px 0 40px;
  box-shadow: 0 15px 30px -10px #00000029;
}

.search-field-text {
  border: 0;
  border-radius: 7px;
  padding: 15px 58px;
  width: 500px;
  box-shadow: 0px 0px 30px #00000029;
  font-weight: bold;
  font-size: 24px;
}

.search-field-text:focus-visible {
  outline: none;
}

.close-button {
  font-size: 40px;
}

.question {
  margin: 0 10px;
  text-decoration: underline;
  font-weight: bold;
}

.slide-enter-active {
  animation: slidein 0.5s;
}

.slide-leave-active {
  animation: slideout 0.5s;
}

@-webkit-keyframes slidein {
  0% {
    -webkit-transform: translateY(-400px);
    transform: translateY(-400px);
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slidein {
  0% {
    -webkit-transform: translateY(-400px);
    transform: translateY(-400px);
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

  @-webkit-keyframes slideout {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    to {
      -webkit-transform: translateY(-400px);
      transform: translateY(-400px);
    }
  }
  @keyframes slideout {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    to {
      -webkit-transform: translateY(-400px);
      transform: translateY(-400px);
    }
  }

  .search-icon {
    display: block;
    position: absolute;
    top: 23px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    left: calc(50% - 226px);
    color: #A5A5A5;
  }
</style>

<script>
import { BIconX, BIconSearch } from "bootstrap-vue";
export default {
  methods: {
    focusInput() {
      this.$refs.search.focus();
    }
  },
  data() {
    return {
      searchVisbility: false,
    };
  },
  mounted() {
    this.$root.$on("showSearch", () => {
      this.searchVisbility = !this.searchVisbility;
    });
  },
  components: {
    BIconX,
    BIconSearch,
  },
};
</script>