<template>
    <nav class="tq-nav tq-shadow">
        <b-container>
            <div class="header-start-mobile">
                <b-icon-list v-b-toggle.sidebar-1/>
                <b-sidebar id="sidebar-1" title="Menu principale" shadow>
                    <div class="py-2">
                        <div class="tracking-field-mobile">
                            <input type="text" placeholder="Suivi de votre expédition">
                        </div>
                        <ul class="menu-list-mobile px-3">
                            <li>
                                <router-link to="/">
                                    Accueil <b-icon-arrow-right-short class="link-icon"/>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/#start">
                                    Démarrer <b-icon-arrow-right-short class="link-icon"/>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/tarification">
                                    Tarification <b-icon-arrow-right-short class="link-icon"/>
                                </router-link>
                            </li>
                           <li>
                            <router-link to="/network">
                              Réseau Althiqa <b-icon-arrow-right-short class="link-icon"/>
                            </router-link>
                           </li>
                        </ul>
                    </div>
                    <div class="action-button-wrapper text-center-mobile">
                        <div class="hint-action-button">Vous êtes déjà un client ?</div>
                        <tq-button to="https://seller.althiqa.ma/authentication">Se connecter</tq-button>
                    </div>
                </b-sidebar>
                <router-link to="/">
                    <img src="~@/assets/imgs/althiqa_logo_arabic_right_black.svg" alt="ALTHIQA" width="100px"/>
                </router-link>
            </div>
            <div class="logo d-none d-lg-block">
                <figure class="tq-navbar-logo-wrapper">
                    <router-link @click.native="scrollToTop" to="/">
                        <img src="@/assets/imgs/logo_althiqa.svg" alt="Althiqa" width="76px">
                    </router-link>
                </figure>
            </div>
            <ul class="tq-navbar-item-wrapper d-none d-lg-block">
                <slot></slot>
            </ul>
            <tq-action-button to="http://forms.althiqa.ma/leads" class="tq-action-header-mobile-position">Rejoignez-nous</tq-action-button>
            <tq-navbar-search-button class="search-button d-none d-lg-block"/>
        </b-container>
    </nav>
</template>

<script>
import TqNavbarSearchButton from './TqNavbarSearchButton.vue'
import TqActionButton from './TqActionButton.vue'
import TqButton from './TqButton.vue'
export default {
    props: {
    },
    components: {
        TqNavbarSearchButton,
        TqActionButton,
        TqButton,
    }
}
</script>

<style lang="scss" scoped>
    $tq-nav-padding: 15px;
    .tq-nav {
        background-color: white;
        padding-top: $tq-nav-padding;
        z-index: 999;
        position: relative;
        .container {
            display: flex;
        }
    }

    .tq-shadow {
        box-shadow: 0px 0px 30px #00000029;
    }


    @media (min-width: 992px) {
        .header-start-mobile {
            display: none;
        }

        .tq-navbar-item-wrapper {
            list-style-type: none;
            margin: 0 0 0 20px;
            display: flex !important;
            flex-direction: row;
            flex-grow: 1;
            flex-flow: row;
        }
    }

    @media (max-width: 992px) {
        .tq-action-header-mobile-position {
            position: fixed;
            right: 14px;
            top: 9px;
            font-size: 12px;
            height: 38px;
            padding: 0 16px;
        }

        .header-start-mobile {
            .bi-list {
                font-size: 24px;
                vertical-align: middle !important;
                margin-right: 10px;
            }
            padding-bottom: 15px;
        }

        .menu-list-mobile li{
            list-style: none;
            border-bottom: 1px solid #eaeded;
            padding: 16px;
        }

        .menu-list-mobile li a{
            color: #393939;
            font-weight: bold;
            font-size: 20px;
        }
    }

    .tracking-field-mobile {
        background-color: #FFC440;
        padding: 1rem 1rem;
        input {
            border: 0;
            border-radius: 7px;
            padding: 8px 16px 8px 16px ;
            width: 100%;
            box-shadow: 0px 0px 30px #00000029;
            font-weight: bold;
            font-size: 18px;
        }
        input::placeholder {
            font-weight: 400;
        }
    }
</style>
