<template>
    <li>
        <router-link class="dropdown-item" :to=to>
            <slot></slot>
        </router-link>
    </li>
</template>

<style lang="scss" scoped>
    @import '~@/assets/styles/variables';
    .dropdown-item {
        color: $text-primary-light-color;
        font-family: "Amazon Ember";
        font-size: 14px;
        padding: 0px;
        margin-bottom: 15px;
    }

    .dropdown-item:hover, .dropdown-item:focus, .dropdown-item:active {
        background: none;
        color: $text-primary-color;

    }

    .dropdown-item:hover:before, .dropdown-item:before {
        content: "";
        position: absolute;
        background: $primary;
        width: 4px;
        height: 22px;
        border-radius: 2px;
        left: 14px;
        opacity: 0;
        transition-duration: 0.2s;
        transition-timing-function: linear;
    }

    .dropdown-item:hover:before, .router-link-active:before  {
        opacity: 1;
    }

</style>

<script>
export default {
    props: {
        to: String,
    }
}
</script>