<template>
    <b-row class="mt-3 mb-3">
        <b-col md="2">
            <h3><strong v-html="newTitle"></strong></h3>
        </b-col>
        <b-col md="10">
            <b-row>
                <b-col>
                    <strong>{{ step }}</strong>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p class="small-text">
                        <slot></slot>
                    </p>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
export default {
    computed: {
        newTitle: function() {
            var newTile = this.title.replace('ère', `<sup class='small-sup'>ère</sup>`);
            return newTile.replace('ème', `<sup>ème</sup>`);
        },
    },
    props:{
        title: String,
        step: String,
    }
}
</script>

<style lang="scss" scoped>

</style>
