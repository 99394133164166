<template>
    <li class="nav-item">
        <router-link class="dropdown-title" :to=to>
            {{ title }} <span class="drop-down-icon"><img src="@/assets/imgs/chevron-down-solid.svg" alt="+"></span>
            <ul class="dropdown">
                <slot></slot>
            </ul>
        </router-link>
    </li>
</template>

<style lang="scss" scoped>
    @import '~@/assets/styles/variables';
    a,a:hover {
        color: $text-primary-color;
        text-decoration: none;
        font-weight: bold;
        padding: 15px 0 20px;
        background: none;
    }

    li {
        position: relative;
        margin: 0 20px 0 16px;
        padding-top: 12px;
        display: inline;
    }

    .dropdown-title:hover .dropdown {
        opacity: 1;
        transform: translate(-30px,16px);
        visibility: visible;
    }

    .dropdown-title:after {
        content: "";
        position: absolute;
        background: $primary;
        width: 100%;
        height: 4px;
        border-radius: 2px;
        opacity: 0;
        left: 0;
        bottom: 0px;
    }

    .nav-item:hover .dropdown-title:after, .router-link-active::after {
        opacity: 1;
    }

    .dropdown-title:hover, .dropdown-title:hover .drop-down-icon img, .dropdown-title .drop-down-icon img,.dropdown-title:after {
        transition-duration: 0.2s;
        transition-timing-function: linear;
    }

    li .drop-down-icon img {
        width: 10px;
        margin-bottom: 1px;
        margin-left: 3px;
    }

    li:hover .drop-down-icon img{
        transform: rotateX(180deg);
    }

    .dropdown {
        cursor: default;
        list-style-type: none;
        position: absolute;
        background: white;
        top: 48px;
        padding: 30px 30px 10px;
        opacity: 0;
        box-shadow: 0 15px 30px -10px #00000029;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        z-index: 0;
        transition: all .2s cubic-bezier(.64,.15,.53,.53);
        visibility: hidden;
        transform: translate(-30px,6px);
        li {
            display: block;
            padding: 5px 0;
        }
    }

</style>

<script>
export default {
    props:{
        title: String,
        to: String,
    }
}
</script>